// resources/js/Pages/Welcome.jsx

import React from "react";
import { Link } from "@inertiajs/react";
import { router } from "@inertiajs/react";
import { useEffect } from "react";

export default function Welcome({ auth }) {
    useEffect(() => {
        router.visit("/dashboard");
    }, []);

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-gray-100">
            {/* Video Background */}
            <video
                autoPlay
                playsInline
                muted
                loop
                className="fixed inset-0 z-0 object-cover w-full h-full"
                poster="https://lirp.cdn-website.com/b5705291/dms3rep/multi/opt/AdPHEaxLQ6DfyGx73hAF_Landing+page+2+2024mp4.v2.0000000-1920w.jpg"
                src="https://vid.cdn-website.com/b5705291/videos/AdPHEaxLQ6DfyGx73hAF_Landing+page+2+2024mp4-v.mp4"
                id="videobgframe-1900443754"
            ></video>
        </div>
    );
}
